/* Mobile Devices (portrait and landscape) */
@media only screen and (max-width: 480px) {
  /* CSS for mobile devices */
  // .container {
  //   background-color: rgb(184, 194, 40);
  // }
  app-sign-in .container.sign-in-container {
    .logo {
      bottom: 0;
      padding: 3rem !important;
    }
    .sign-in-content {
      flex-direction: column;

      .left {
        height: 64vh !important;
        width: 100% !important;
        padding: 3rem;
        zoom: 98%;
      }
      .right {
        height: 30vh !important;
        width: 100% !important;
        padding: 3rem;
        zoom: 94%;
      }
    }
  }
}

/* Mobile Devices (landscape) */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  /* CSS for landscape orientation on mobile devices */
  .container {
    max-width: 768px;
    margin: 0;
    // background-color: rgb(185, 9, 76);
  }
  app-file-upload form.form-container {
    flex-direction: column;
    .metadata-container {
      flex-direction: row !important;
      width: 100% !important;
      .mat-mdc-card {
        width: 50%;
      }
    }
    .files-container {
      flex-direction: row !important;
      width: 100% !important;
    }
  }
}

/* Tablets and Small Laptops */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  /* CSS for tablets and small laptops */
  .container {
    max-width: 1024px;
    // background-color: rgb(9, 185, 176);
  }
}

/* Tablets and Laptops */
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  /* CSS for tablets and laptops */
  .container {
    max-width: 1280px;
    // background-color: blue;
  }
}

/* Desktops and Laptops */
@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  /* CSS for desktops and laptops */
  .container {
    max-width: 1440px;
    // background-color: green;
  }
}

/* Larger Desktops and Monitors */
@media only screen and (min-width: 1441px) {
  /* CSS for larger desktops and monitors */
  .container {
    max-width: unset;
    // background-color: red;
  }
}
