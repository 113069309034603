.page {
  .page-head {
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-family: "OrstedSansBold";
      color: #3b4956;
    }
  }
  .sign-in-img-bg {
    margin: 0;
    padding: 0;
    background-image: url("/assets/images/ocean2.svg");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 0;
    left: 0;
  }
  section.initial-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    position: relative;
    width: 100%;
    top: 15%;
    left: 0;
    img.default-image {
      width: 60vw;
      max-width: 624px;
    }
  }
}
