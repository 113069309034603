body {
  background: var(--bs-light);
}
main {
  background: white;
  max-width: 1440px;
  margin: 0 auto;
  section.main_content {
    height: 100%;
    padding: 1rem;
    width: 100%;
  }
}
ul, li {
  list-style: none;
}