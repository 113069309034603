.btn {
  position: relative;
  display: inline-flex;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  border: 1px solid transparent;
  overflow: hidden;
  width: auto;
  padding: 16px 32px;
  background-color: transparent;
  font-family: "OrstedSansBold";
  font-size: 18px;
  border-radius: 8px;
  min-height: 40px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  &.full {
    width: 100%;
  }
  &.primary {
    background-color: #007bb9;
    color: white;
    &:hover {
      background-color: #4099da;
      &:disabled {
        background-color: #007bb9;
      }
    }
  }
  &.secondary {
    background-color: #fff;
    color: #3b4956;
    border: solid 1px #3b4956;
    &:hover {
      color: #007bb9;
      border-color: #007bb9;
    }
  }
  &.btn-white {
    background-color: #fff;
    color: #007bb9;
  }
  &.secondary-outline {
    background-color: transparent;
    color: #fefefe;
    font-size: 1rem;
    border: solid 1px #c2c3cd;
    &:hover {
      background-color: #c2c3cd4a;
    }
  }
  &:disabled {
    cursor: not-allowed !important;
    pointer-events: unset;
    &::before {
      content: "";
      height: 100%;
      width: 100%;
      opacity: 0%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  //   &.btn-signin {
  //     background-color: #007bb9;
  //     color: white;
  //   }
}
