:root {
  --vh: 1vh;
  --nav: 0px;

  // Blues
  --bio-blue-70: #007bb9;
  --bio-blue-50: #4099da;
  --bio-blue-30: #8cc2e9;
  --bio-blue-10: #ecf5fb;

  // Black & White
  --bio-black: #222222;
  --bio-white: #ffffff;

  // Greys
  --bio-grey-100: #3b4956;
  --bio-grey-75: #6c7680;
  --bio-grey-50: #c4c8cc;
  --bio-grey-25: #d8dbdd;
  --bio-grey-10: #ebedee;
  --bio-grey-5: #f5f6f7;

  // Warm Grey
  --bio-warm-grey-100: #B7ADA5;
  --bio-warm-grey-75: #C9C1BB;
  --bio-warm-grey-50: #D8D1CA;
  --bio-warm-grey-25: #EDEAE8;
  --bio-warm-grey-10: #F8F7F6;

  // Aqua
  --bio-aqua-70: #357873;
  --bio-aqua-50: #8ECDC8;
  --bio-aqua-30: #BBE1DE;
  --bio-aqua-10: #E8F5F4;

  // Aubergine
  --bio-aubergine-70: #463553;
  --bio-aubergine-50: #644C76;
  --bio-aubergine-30: #C2B2CD;
  --bio-aubergine-10: #E0D9E6;

  // Red
  --bio-red-70: #C41B1B;
  --bio-red-50: #E85757;
  --bio-red-30: #F4ABAB;
  --bio-red-10: #FADDDD;

  // Yellow
  --bio-yellow-70: #DD9E03;
  --bio-yellow-50: #FDD779;
  --bio-yellow-30: #FEE7AF;
  --bio-yellow-10: #FFF7E4;
}
