// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$data-products-biodiv-web-app-primary: mat.define-palette(mat.$indigo-palette);
$data-products-biodiv-web-app-accent: mat.define-palette(
  mat.$blue-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$data-products-biodiv-web-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$data-products-biodiv-web-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $data-products-biodiv-web-app-primary,
      accent: $data-products-biodiv-web-app-accent,
      warn: $data-products-biodiv-web-app-warn,
    ),
  )
);

// @import "@angular/material/prebuilt-themes/indigo-pink.css";

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($data-products-biodiv-web-app-theme);

* {
  padding: 0;
  margin: 0;
  font-family: "OrstedSansRegular", "Montserrat", sans-serif;
}

// Global styles for HTML and body
html,
body {
  background-color: whitesmoke;
  font-family: "OrstedSansRegular", "Roboto", sans-serif;
  height: 100%;
  overflow: hidden;
}

// Container styling
.container {
  width: 100%;
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  z-index: 1;
  position: relative;
  // zoom: 125%;
}
.main_content,
.mat-drawer-content {
  background-color: #ebedee;
  background-image: url("/assets/images/ocean2.svg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  overflow-x: hidden !important;
  height: 100vh;
  width: 100vw;
  &::before {
    content: "";
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    z-index: 0;
  }
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9; /* Adjust as needed to make sure it's on top */
}

// Media query for responsiveness
// @media (min-width: 1200px) {
//   // Adjust styles for screens smaller than 768px
//   .container {
//     max-width: 1440px;
//   }
// }

//override mat style
* .mat-mdc-card {
  position: relative;
  border: none !important;
  border-radius: 16px !important;
  padding: 1rem;
  box-shadow: 0 0 4px 4px #f1f4f9;
}

// .mat-toolbar-row {
//   height: 6.313rem !important;
// }

.mat-mdc-form-field {
  .mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    font-weight: 700;
  }
  &.mat-form-field-invalid {
    .mdc-notched-outline__notch {
      border-top: solid 1px;
      border-top-color: red !important;
    }
    &.mat-focused {
      .mat-mdc-form-field-flex
        .mdc-notched-outline
        .mdc-notched-outline__notch {
        border-top-width: 2px !important;
      }
    }
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
        .mdc-text-field--focused
      ):hover {
      border-color: var(--mdc-outlined-text-field-hover-outline-color);
    }
  }
  .mdc-notched-outline__notch {
    border-top: solid 1px var(--mdc-outlined-text-field-outline-color) !important;
  }

  &:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
}

.mdc-list-item.mdc-list-item--with-one-line {
  padding: 2rem;
  font-weight: 700;
}

@import "./assets/scss/main.scss";
