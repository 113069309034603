.form-field {
  flex: 1;
  // min-width: 230px;
  position: relative;
  label {
    font-size: 14px;
    padding: 0 0 4px;
    font-family: "OrstedSansRegular";
    font-weight: 600;
    color: #6c7680;
  }
  &:has(.ng-invalid.ng-touched) {
    label {
      color: #e85757;
    }
  }
  // Material Select
  mat-select.custom-mat-select {
    padding: 0;
    border: solid 1px gray;
    border-radius: 12px;
    height: 50px;
    width: 100%;
    .mat-mdc-select-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        display: none;
      }
      &::before {
        content: "keyboard_arrow_down";
        font-family: "Material Icons";
        font-size: 1.5rem;
        margin: 0rem;
      }
    }
    &.ng-invalid.ng-touched {
      border-color: #e85757;
      .mat-mdc-select-arrow {
        color: #e85757;
      }
    }
    &:focus {
      border: solid 1px #007bb9;
      .mat-mdc-select-arrow {
        color: #007bb9;
      }
    }
    .mat-mdc-select-trigger {
      width: 100%;
      height: 49px;
      padding: 16px;
    }
  }
  // End: Material Select
  // Material Input field
  .custom-mat-input {
    display: flex;
    padding: 0 1rem;
    height: 50px;
    border-radius: 12px;
    border: solid 1px gray;
    width: 100%;
    outline: none;
    &.ng-invalid.ng-touched {
      border-color: #e85757;
    }
    &:focus {
      border: solid 1px #007bb9;
    }
  }
  // End: Material Input field
  .password-visibility {
    position: absolute;
    right: 4px;
    top: 40px;
    .mdc-checkbox {
      position: relative;
      &::before {
        content: "visibility_off";
        font-family: "Material Icons";
        font-size: 24px;
        position: absolute;
        top: auto;
        left: 8px;
        line-height: 20px;
        color: #6c7680;
      }
      .mdc-checkbox__background {
        visibility: hidden;
      }
    }
    &.mat-mdc-checkbox-checked {
      .mdc-checkbox {
        &::before {
          content: "visibility";
        }
      }
    }
  }
}

.form-action-button-container {
  display: flex;
  gap: 1rem;
}

span.option-counter {
  position: absolute;
  right: 40px;
}
