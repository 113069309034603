@font-face {
  font-family: "OrstedSansRegular";
  font-display: fallback;
  src: url("OrstedSans-Regular.woff2") format("woff2"),
    url("OrstedSans-Regular.woff") format("woff"),
    url("OrstedSans-Regular.eot") format("embedded-opentype");
}

@font-face {
  font-family: "OrstedSansLight";
  font-style: normal;
  font-weight: 200;
  font-display: fallback;
  src: url("OrstedSans-Light.woff2") format("woff2"),
    url("OrstedSans-Light.woff") format("woff"),
    url("OrstedSans-Light.eot") format("embedded-opentype");
}

@font-face {
  font-family: "OrstedSansBold";
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
  src: url("OrstedSans-Bold.woff2") format("woff2"),
    url("OrstedSans-Bold.woff") format("woff"),
    url("OrstedSans-Bold.eot") format("embedded-opentype");
}

@font-face {
  font-family: "OrstedSansBlack";
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: url("OrstedSans-Black.woff2") format("woff2"),
    url("OrstedSans-Black.woff") format("woff"),
    url("OrstedSans-Black.eot") format("embedded-opentype");
}

@font-face {
  font-family: "OrstedSansItalic";
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url("OrstedSans-Italic.woff2") format("woff2"),
    url("OrstedSans-Italic.woff") format("woff"),
    url("OrstedSans-Italic.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Orsted Sans";
  font-display: fallback;
  src: url("OrstedSans-Regular.woff2") format("woff2"),
    url("OrstedSans-Regular.woff") format("woff"),
    url("OrstedSans-Regular.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Orsted Sans";
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url("OrstedSans-Light.woff2") format("woff2"),
    url("OrstedSans-Light.woff") format("woff"),
    url("OrstedSans-Light.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Orsted Sans";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("OrstedSans-Bold.woff2") format("woff2"),
    url("OrstedSans-Bold.woff") format("woff"),
    url("OrstedSans-Bold.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Orsted Sans";
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: url("OrstedSans-Black.woff2") format("woff2"),
    url("OrstedSans-Black.woff") format("woff"),
    url("OrstedSans-Black.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Orsted Sans";
  font-style: italic;
  font-display: fallback;
  src: url("OrstedSans-Italic.woff2") format("woff2"),
    url("OrstedSans-Italic.woff") format("woff"),
    url("OrstedSans-Italic.eot") format("embedded-opentype");
}
