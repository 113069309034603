$cacheBust: 6;
@font-face {
  font-family: "dong-maine";
  src:
    url("./dong-maine.woff2?v=#{$cacheBust}") format("woff2"),
    url("./dong-maine.woff?v=#{$cacheBust}") format("woff"),
    url("./dong-maine.eot?v=#{$cacheBust}") format("embedded-opentype"),
    url("./dong-maine.ttf?v=#{$cacheBust}") format("truetype"),
    url("./dong-maine.svg#dong-maine") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
